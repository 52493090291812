body, html {
  margin: 0;
  padding: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  touch-action: auto; /* Allow touch interactions */
  user-select: none; /* Optional: prevent text selection */
}

.scrollable-container {
  height: 100vh; /* Adjust as needed */
  overflow: auto;
}

/* Hide scrollbar for Webkit browsers */
::-webkit-scrollbar {
  width: 0;
  background: transparent; /* Optional */
}
