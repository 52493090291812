@tailwind base;
@tailwind components;
@tailwind utilities;

body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}
@layer components {
  /* .btn-primary {
      @apply py-1.5 px-3 text-center bg-blue-700 rounded-md text-white;
  } */
}

.animate-marquee {
  animation: marquee 10s linear infinite;
  /* Adjust duration and timing function as needed */
  white-space: nowrap;
  /* Prevent text from wrapping */
}